import qs from 'query-string';

import { ElementAttributes } from '@ratehub/base-ui';


/**
 * Begin monitoring for clicks on any language link.
 * If clicked, update their href with the query parameters of the window.
 * This allows us to persist any query params that were dynamically
 * added to the page by the client during runtime onto the next page load.
 * Why? Because our SPAs do this.
 */
function initializeLanguageLinks() {
    // Don't run on the server
    if (typeof window === 'undefined') {
        return;
    }

    // NOTE: this relies on the event bubble for an onClick of an anchor to reach the root document.
    // This solution was chosen as it elegantly handles dynamic generation of these links.
    document.addEventListener('click', event => {
        const target = event.target;

        if (
            !target
            // Only alter anchors which are marked as language links
            || target.tagName !== 'A'
            || !target.classList?.contains(ElementAttributes.LANGUAGE_LINK_CLASS)
            || !target.hasAttribute('href')
        ) {
            return;
        }

        // Need to pass second argument because target.href is probaby a relative path.
        // As long as the second argument contains the scheme+domain, a relative path will be resolved correctly.
        // If the target.href is an absolute path, it should still parse the URL correctly.
        const targetUrl = new URL(target.getAttribute('href'), window.location.origin);

        // Grab query parameters
        const windowQueryParameters = qs.parse(window.location.search);
        const targetQueryParameters = qs.parse(targetUrl.search);

        // Grab the non-canconical part of the URL
        // BEWARE: This breaks on local because canonicalURL.pathname will start
        // with "3000" (the port your server is on). Why that's considered part
        // of the pathname is beyond me but this will work as expected so long
        // as you're on a host which doesn't specify a weird port (e.g.
        // ratehub.ca). The result of this that the alternate language link gets
        // all messed up ON CLICK so it looks correct until you click it and it
        // 404s, making you question your sanity and examine how your life led
        // to this.
        const canonicalURL = document.querySelector('link[rel=canonical]')?.href
            ? new URL(document.querySelector('link[rel=canonical]').href)
            : null;
        const nonCanonicalURLParts = canonicalURL
            ? location.pathname.replace(canonicalURL.pathname, '')
            : '';

        // REQUIREMENT: update the href to include the non-canonical parts of the URL & query params on the window.
        targetUrl.pathname = `${targetUrl.pathname}${nonCanonicalURLParts}`;
        targetUrl.search = qs.stringify({
            ...windowQueryParameters,
            ...targetQueryParameters,
        });

        target.setAttribute('href', targetUrl);
    });
}

export default initializeLanguageLinks;
