import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


/**
 * 
 * @param {object} params
 * @param {string} params.documentId
 * @param {string} params.documentType
 * @param {string} params.sortByCreatedAt - Optional
 * @param {object} params.limit - Optional
 *  
 * @returns {Promise<void>}
 */
async function fetchDocumentEvents({
    documentId,
    documentType,
    sortByCreatedAt,
    limit,
}) {
    // fetchJSON handles errors for us
    const response = await fetchJSON({
        url: API_ENDPOINT,
        options: {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        },
        params: {
            documentId,
            documentType,
            ...(sortByCreatedAt ? { sortByCreatedAt } : {}),
            ...(limit ? { limit } : {}),
        },
    });

    return response.data;
}

export const API_ENDPOINT = `${Config.PROFILE_BFF_URL}/document-events`;

export default fetchDocumentEvents;