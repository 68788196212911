import dayjs from 'dayjs';
import get from 'lodash.get';

import Locales from '../definitions/Locales';
import Formats from '../definitions/Formats';
import noticeError from '../functions/noticeError';
import isCMS from '../functions/isCMS';

/**
 * Loop through a content string and replace shortcodes with the requested data
 * If function is executing on the front end, errors will be logged to NewRelic.
 * If function is executing in a WordPress context, Errors will be thrown which WordPress can catch.
 *
 * @param {string} content - a content string
 * @param { { key: string, data: * }[] } dataSources - array of specially formatted data objects, where data property for each data source will vary
 * @param {object} options
 * @param {string} [options.locale='en-CA'] - locale string
 * @param {object} [options.pageSettings]
 * @param {string} [options.pageSettings.publishedDate] - date string
 * @param {string} [options.pageSettings.modifiedDate] - date string
 * @returns {string} Content string with shortcodes replaced by requested values
 */
function replaceShortcodes(
    content,
    dataSources = [],
    options = { locale: Locales.ENGLISH },
) {
    let enrichedContent = content;
    let dataSourceContent;

    let shortcode;
    while ((shortcode = getShortcode(enrichedContent))) {
        // shortcode will now look like this
        // [0] {string} the entire shortcode as entered by the CMS author, e.g. "${_rh.WHATEVER…}"
        // [1] {string} data source name plus the rest of the path of the property to get,
        //     with optional trailing formatting instructions

        // separate the optional data formatting from the path to the data
        let [ dataPath, dataFormat ] = shortcode[1].split('|').map(str => str.trim());
        dataPath = dataPath.split('.');

        // extract the key for the data source, leaving just the path part in the dataPath array
        const dataSourceKey = dataPath.shift();
        const dataSource = dataSources.find(source => source.key === dataSourceKey);

        // reset this to empty string for each shortcode
        dataSourceContent = '';

        // Special case where we don’t have to fetch any data
        if (dataSourceKey === 'date') {
            dataSourceContent = resolveDate(dataPath[0], dataFormat, options);
        } else if (dataSource) {
            const format = getFormatFromString(dataFormat);

            // get requires the path to be a string when dealing with arrays
            // Example: [ 'fixed[5]', 'value' ] should be 'fixed[5].value'
            dataSourceContent = get(dataSource?.data, dataPath.join('.'), '');

            if (dataSourceContent === '') {
                const errorMessage = `We could not resolve the shortcode “${shortcode[0]}”. Double-check it for typos.
                    ${dataSourceKey === 'deposits' ? ' If the shortcode is valid, is it also monetized?' : ''}`;

                if (isCMS()) {
                    throw new Error(errorMessage);
                } else {
                    noticeError(errorMessage, {
                        dataSourceKey,
                        dataSourceKeys: `[ “${dataSources.map(src => src.key).join('”, “')}” ]`,
                    });
                }
            } else if (format) {
                dataSourceContent = formatString(dataSourceContent, options.locale, format);
            }
        } else {
            const errorMessage = '[replaceShortcodes] could not find matching dataSource';

            if (isCMS()) {
                throw new Error(errorMessage);
            } else {
                noticeError(errorMessage, {
                    dataSourceKey,
                    dataSourceKeys: `[ “${dataSources.map(src => src.key).join('”, “')}” ]`,
                });
            }
        }

        // Update content with requested value, or an empty string if unresolvable
        enrichedContent = enrichedContent.replace(shortcode[0], dataSourceContent);
    }

    return enrichedContent;
}


/**
 * Get the first shortcode found within a string
 *
 * @param {string} string
 * @returns {array} Returns an array with the following format:
 *      [0] full string match
 *      [1] group match (our 'function' string)
 *      .index offset of match within string
 *      null if nothing is found
 */
function getShortcode(string) {
    if (!string) {
        return false;
    }

    // Regex looks for `${_rh.WHATEVER}` and returns one match, having already removed the leading '_rh'
    return string.match(/\${_rh\.(.+?)}/);
}

/**
 * getFormatFromString
 * @param {string} sourceString text from which to extract the format, e.g. "format(percent2)"
 * @param {string} dataType type of format to extract (essentially just a boolean for isDate, at the moment)
 * @returns {undefined|string|Object}
 *      undefined: if sourceString is empty
 *      date: the string extracted from within sourceString
 *      else: the Object defined within `Formats.number` indexed by the string extracted from within sourceString
 */
function getFormatFromString(sourceString, dataType = 'number') {
    if (!sourceString) {
        return undefined;
    }

    // NO quotations marks around the specified format style
    // the CMS may mess with those within HTML editors: e.g. format(&#8216;YYYY-mm-dd&#8217;)
    const match = /format\((.*)\)/g.exec(sourceString);
    const formatStyle = match?.[1];     // did our regex group find a match?

    // for dates, they are already formatted with DayJS, so just pass the string along directly
    if (dataType === 'date' && formatStyle) {
        return formatStyle;
    }

    return formatStyle
        ? Formats.number[formatStyle]
        : undefined;
}

/**
 * formatString
 * @param {number} value The number we are converting to a formatted string
 * @param {string} locale Locale string
 * @param {Object} format How to display the value, including the requested style
 * @param {string} format.style for example, 'number', 'currency', or 'percent' (see Formats.js)
 * @returns {string} Locale formatted string
 */
function formatString(value, locale, format) {
    // Percentages need to be a value from 0-1,
    // This does assume that `value` is not going to be formatted this way
    if (format?.style === 'percent') {
        value = value / 100;
    }

    // Please note that this logic would need updating to support non-Canadian locales
    return value.toLocaleString(locale, format);
}

/**
 * Found a date shortcode; resolve it!
 * @param dateSelector
 * @param dataFormat
 * @param options
 * @returns {string} current date in ISO8601 or empty string if dateSelector is unrecognized
 */
function resolveDate(dateSelector, dataFormat, options = {}) {
    let dateString;

    const { modifiedDate, publishedDate } = (options.pageSettings || {});
    if (dateSelector === 'modifiedDate') {
        dateString = modifiedDate;
    } else if (dateSelector === 'publishedDate') {
        dateString = publishedDate;
    } else if (dateSelector === 'today') {
        dateString = dayjs().format();
    }

    if (dateString) {
        return getDateStringForLocale(
            options.locale,
            dateString,
            getFormatFromString(dataFormat, 'date'),    // get optional date formatting, if present
        );
    }

    // if we’re here, we did not have a matching dateSelector
    noticeError(`[replaceShortcodes] unexpected date selector: “${dateSelector}”`);

    return '';
}


const DEFAULT_DATE_FORMAT_EN = 'MMMM D, YYYY'; // e.g. November 1, 2022
const DEFAULT_DATE_FORMAT_FR = 'D MMMM YYYY'; // e.g. 1 novembre 2022

/**
 * Returns a formatted date string based on the locale
 *
 * @param {string} [locale='en-CA'] Locale string
 * @param {string} [date] Standard date string (but undefined implies today)
 * @param {string} [format] DayJS format string
 * @returns {string} Formatted date string
 */
function getDateStringForLocale(locale = Locales.ENGLISH, date, format) {
    const dateFormat = format || (locale === Locales.FRENCH
        ? DEFAULT_DATE_FORMAT_FR
        : DEFAULT_DATE_FORMAT_EN);

    return dayjs(date).locale(locale).format(dateFormat);
}

export default replaceShortcodes;
