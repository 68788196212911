import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useCombobox } from 'downshift';

import omitKeys from '../functions/omitKeys';
import MessagePropType from '../definitions/MessagePropType';
import MessageStyles from '../definitions/MessageStyles';
import trackHeapEvent from '../functions/trackHeapEvent';
import formatSelectOptions from '../functions/formatSelectOptions';
import noticeError from '../functions/noticeError';
import useDebouncedCallback from '../hooks/useDebouncedCallback';
import SelectBase from './SelectBase';
import withErrorMessageContainer from './withErrorMessageContainer';
import InputController from './InputController';


const DEFAULT_ERROR_MESSAGE = (
    <FormattedMessage 
        id="base-ui.autocomplete.default-error"
        defaultMessage="Please select an option"
    />
);


function Autocomplete({
    id,
    name,

    value = null,

    onChange,
    onFocus,
    onBlur,
    onValidityChange,
    onValidate,

    isDisabled = false,
    isRequired = true,

    label,
    placeholder,
    loadingPlaceholder,
    noResultsPlaceholder,

    fetchSuggestions,
    suggestionRenderer,

    canShowInvalid,

    errorMessageStyle,

    className,
    inputClassName,

    // Users will need to configure these props to customize the parsing and validation
    defaultErrorMessage = DEFAULT_ERROR_MESSAGE,
    getParsedValue,
    getFormattedValue,

    ...otherProps
}) {
    const intl = useIntl();

    const [ options, setOptions ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ hasFetchError, setHasFetchError ] = useState(false);
                
    return (
        <InputController
            id={id}
            name={name}
            value={value}
            isDisabled={isDisabled}
            isRequired={isRequired}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onValidityChange={onValidityChange}
            onValidate={onValidate}
            canShowInvalid={canShowInvalid}
            className={className}
            defaultErrorMessage={defaultErrorMessage}
            getParsedValue={getParsedValue}
            getFormattedValue={getFormattedValue}
            {...otherProps}
        >
            {(inputProps, inputState) => {
                const handleFetchSuggestions = useDebouncedCallback(async ({ inputValue }) => {
                    const query = inputValue?.trim();
                    let suggestions = [];
                    setIsLoading(true);

                    if (query) {
                        try {
                            suggestions = await fetchSuggestions(query);
                            setHasFetchError(false);
                        } catch (error) {
                            noticeError(error, {
                                message: '[Autocomplete] - error fetching results.',
                                query,
                            });
                            setHasFetchError(true);

                            // Make sure to update input state to signify we have no value.
                            // NOTE: Do not raise an onChange which is a signal that we have a valid value.
                            inputState.formattedValue = null;

                            // This would only occur if the user happens to blur/close
                            //  the menu before the error is caught. In this case,
                            //  we want to clear the input value so its obvious that
                            //  the user has nothing selected and cannot proceed.
                            if (!isAlreadyOpen) {
                                setInputValue('');
                            }
                        }
                    }

                    setOptions(suggestions?.map(suggestion => ({ value: suggestion })) ?? []);
                    setIsLoading(false);

                    // Set the first option to be highlighted
                    setHighlightedIndex(0);
                });

                // Options need to be formatted to 1) ensure we have a valid label and value for each
                //  and 2) set an index value to each option due to Downshift's tracking and option group support.
                //  Flat options are needed so Downshift can query all options without option group titles.
                const {
                    formattedOptions,
                    flatOptions,
                } = formatSelectOptions({
                    options,
                    renderOptionLabel: suggestionRenderer,
                    intl,
                });

                const {
                    isOpen: isAlreadyOpen,
                    highlightedIndex,

                    setInputValue,
                    setHighlightedIndex,

                    getComboboxProps,
                    getInputProps,
                    getMenuProps,
                    getItemProps,
                } = useCombobox({
                    id: inputProps.id,
                    inputId: inputProps.id,
                    initialInputValue: getSuggestionValue(inputProps.value, suggestionRenderer),

                    // We need to send the flat options since Downshift is index-based and option group titles cannot be selected.
                    items: flatOptions,
                    itemToString: (i) => i ? i.label : '',

                    onSelectedItemChange: handleSelectedItemChange,
                    onInputValueChange: handleFetchSuggestions,
                    onIsOpenChange: handleIsOpenChange,
                });
    
                function handleSelectedItemChange({ selectedItem }) {
                    const newValue = selectedItem?.value ?? null;

                    trackHeapEvent('Select value changed', { name, value: newValue });

                    inputProps.onChange?.(newValue);
                }

                function handleIsOpenChange({ isOpen, selectedItem }) {
                    // REQUIREMENT: On Blur, reset the input value to either 1) the selected item or 2) empty value.
                    //  The menu closes when a blur event occurs, and so this requirement is performed
                    //  on menu close instead of within our blur handler. To get this working on blur
                    //  we needed to introduce a timeout. This was the only method to get around it.
                    if (isOpen) {
                        return;
                    }

                    if (hasFetchError) {
                        // If we have an API error, we want to make it clear
                        //  they have not actually selected a value
                        setInputValue('');
                    } else if (!selectedItem && value) {
                        // If an initial value is passed and then removed by a user
                        // without selecting a suggestion from the dropdown
                        // we will reinstitute the initial value.
                        // Reason: Without this a user would be able to bypass this input
                        // if required by its field
                        if (!inputProps.required) {
                            inputProps.onChange?.(null);
                            setInputValue('');
                        } else {
                            setInputValue(getSuggestionValue(value, suggestionRenderer));
                        }
                    } else {
                        setInputValue(selectedItem ? selectedItem.label : '');
                    }
                }
    
                /* This useEffect is needed if the value is updated
                    programmatically after first render */
                const renderedValue = inputProps.value != null 
                    ? getSuggestionValue(inputProps.value, suggestionRenderer) 
                    : undefined; // rather than comparing deep object, just compare the output of rendered value
                
                useEffect(() => {
                    if (renderedValue && !hasFetchError) {
                        setInputValue(renderedValue);
                    }
                }, [ renderedValue, hasFetchError ]);

                // Adding error message container here temporarily until we
                //  refactor field/inputs. Ideally Autocomplete would manage its own
                //  error states, but here we're just doing it for fetch errors.
                return (
                    <SelectBaseWithErrorMessage
                        className={inputProps.className}

                        /* Props for withErrorMessageContainer */
                        /* When we have a fetch error, override all these values
                            to make it super clear the user cannot proceed. */
                        errorMessage={hasFetchError ? MESSAGES.FETCH_ERROR_MESSAGE : inputState.errorMessage}
                        errorMessageStyle={hasFetchError ? MessageStyles.DEFAULT : errorMessageStyle}
                        shouldShowInvalid={hasFetchError || inputState.shouldShowInvalid}

                        isOpen={isAlreadyOpen}

                        options={addPropsToOptions(formattedOptions, getItemProps)}
                        optionPlaceholder={getOptionPlaceholder({
                            options,
                            isLoading,
                            loadingPlaceholder,
                            noResultsPlaceholder,
                            hasFetchError,
                        })}
                        highlightedIndex={highlightedIndex}

                        disabled={isDisabled}

                        label={label}
                        placeholder={placeholder ?? intl.formatMessage(MESSAGES.DEFAULT_INPUT_PLACEHOLDER)}

                        comboboxProps={getComboboxProps({
                            disabled: inputProps.disabled,
                        })}

                        inputProps={getInputProps({
                            ...omitKeys(inputProps, [ 'value', 'onChange' ]), // we don't want to use this input as the controlled input
                            className: inputClassName,
                            autoComplete: 'new', // disable browser autocomplete
                            'data-lpignore': true, // disable LastPass
                        })}

                        menuProps={getMenuProps()}
                    />
                );
            }}
        </InputController>
    );
}

Autocomplete.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,

    value: PropTypes.any,

    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,

    /* Handlers */
    /**
     * On Change handler.
     *
     * Must be used to eventually pass an option object down to the value prop.
     *
     * @param {Object} option
     */
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onValidityChange: PropTypes.func,
    onValidate: PropTypes.func,

    label: MessagePropType,
    placeholder: MessagePropType,
    loadingPlaceholder: MessagePropType,
    noResultsPlaceholder: MessagePropType,
    /**
     * Fetch Suggestions.
     *
     * An async method which accepts a query string and eventually returns an array of value-label pairs.
     * Suggestions can be sorted and filtered in the appropriate fetch suggestions handler if needed.
     *
     * @param query
     * @returns {Promise<Array>} suggestions
     *
     * @example
     * const results = [{
     *  value: "The raw value",
     *  label: "What the user sees"
     * }]
     */
    fetchSuggestions: PropTypes.func.isRequired,
    /**
     * Suggestion renderer.
     *
     * Allows you to customize how the label for a suggestion is generated by returning a string, or to
     * return JSX to customize how it is rendered.
     * Currently applies to both the dropdown and the currently selected value.
     */
    suggestionRenderer: PropTypes.func.isRequired,

    /* Validation flags */
    canShowInvalid: PropTypes.bool,
    errorMessageStyle: PropTypes.oneOf(Object.values(MessageStyles)),

    className: PropTypes.string,
    inputClassName: PropTypes.string,

    /* Customization */
    defaultErrorMessage: MessagePropType,
    /**
     * Converts option object to a value type; if not provided, the value will be the option object.
     */
    getParsedValue: PropTypes.func,
    /**
     * Converts value type to an option to be displayed; should be provided if getParsedValue overrides the type.
     */
    getFormattedValue: PropTypes.func,
};


const SelectBaseWithErrorMessage = withErrorMessageContainer(SelectBase);

/* Helper functions */
function getSuggestionValue(value, suggestionRenderer) {
    return value != null
        ? suggestionRenderer?.(value) ?? value 
        : '';
}

function getOptionPlaceholder({ options, isLoading, loadingPlaceholder, noResultsPlaceholder, hasFetchError }) {
    if (hasFetchError) {
        return MESSAGES.FETCH_ERROR_MESSAGE;
    } else if (isLoading) {
        return loadingPlaceholder ?? MESSAGES.DEFAULT_LOADING_PLACEHOLDER;
    } else if (options.length === 0) {
        return noResultsPlaceholder ?? MESSAGES.DEFAULT_NO_RESULTS_PLACEHOLDER;
    }
    return undefined;
}

function addPropsToOptions(options, getItemProps) {
    let i = 0;

    // We only want to apply the props to the selectable options,
    //  and we need to determine their index without the option group titles
    //  to get these props from Downshift.
    return options.map(option => {
        if (option.options) {
            return {
                ...option,
                options: option.options.map(innerOption => {
                    const newInnerOption = {
                        ...innerOption,
                        index: i,
                        otherProps: { ...getItemProps({ item: innerOption, index: i }) },
                    };

                    i++;
                    return newInnerOption;
                }),
            };
        }

        const newOption = {
            ...option,
            index: i,
            otherProps: { ...getItemProps({ item: option, index: i }) },
        };

        i++;
        return newOption;
    });
}

const MESSAGES = defineMessages({
    DEFAULT_LOADING_PLACEHOLDER: {
        id: 'base-ui.autocomplete.defaultLoadingPlaceholder',
        defaultMessage: 'Loading...',
    },
    DEFAULT_NO_RESULTS_PLACEHOLDER: {
        id: 'base-ui.autocomplete.defaultNoResultsPlaceholder',
        defaultMessage: 'No Results',
    },
    DEFAULT_INPUT_PLACEHOLDER: {
        id: 'base-ui.autocomplete.defaultInputPlaceholder',
        defaultMessage: 'Type to search',
    },
    FETCH_ERROR_MESSAGE: {
        id: 'base-ui.autocomplete.fetchErrorMessage',
        defaultMessage: 'Something went wrong, please try again later',
    },
});

export default Autocomplete;
