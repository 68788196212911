export { default as validate } from './functions/validate';
export { default as extractMetadata } from './functions/extractMetadata';
export { default as SchemaOptions } from './definitions/SchemaOptions';

// Base Document
export { default as InsLifeDocument } from './models/InsLifeDocument';

// Domain Models
export { default as Quote } from './models/Quote';

// Life Definition Values
export { default as CoverageAmountValues } from './definitions/CoverageAmountValues';
export {
    default as DisabilityCoverageAmountValues,
    DisabilityCoverageAmountHighestValue,
    DisabilityCoverageAmountSurplusValue,
} from './definitions/DisabilityCoverageAmountValues';
export {
    InsLifeWizardFormTypes,
    InsLifeWizardInsuranceTypes,
    InsuranceTypesMappedToFormTypes,
    QuotableInsuranceTypes,
} from './definitions/InsuranceTypeValues';
export {
    StandardTermLengthValues,
    WholeTermLengthValues,
    UniversalTermLengthValues,
    TermLengthDefaultValues,
} from './definitions/TermLengthValues';

export const VERSION = 1;
