import queryString from 'query-string';

import Config from '../definitions/Config';
import getAffiliateDecoratedURL from './getAffiliateDecoratedURL';
import noticeError from './noticeError';


/**
 * Navigate the browser to a new page, optionally in a new tab.
 *
 * @param {string} url URL of new page
 * @param {object} options
 * @param {boolean} [options.shouldClearUnload=true] clear window.onbeforeunload first?
 * @param {boolean} [options.isNewTab=false] open in new tab?
 * @param {boolean} [options.isRedirect=false] redirect to new page without adding to history?
 * @param {object} [options.queryParams=undefined] object of query params to append to url
 * 
 * @returns {void}
 */

function navigateWindowTo(
    url,
    options = {
        shouldClearUnload: true,
        isNewTab: false,
        isRedirect: false,
        queryParams: undefined,
    },
) {
    if (typeof window === 'undefined') {
        throw new Error('[navigateWindowTo.js] Trying to navigate when window is undefined');
    }

    if (!options.isNewTab && options.shouldClearUnload) {
        // We want to make sure the pop-up doesn’t occur if set
        // since the user will be navigated to a new page
        window.onbeforeunload = null;
    }

    // If we’re going to an internal URL include our affiliate query params.
    if (url.startsWith(Config.RATEHUB_BASE_URL) || url.startsWith('/')) {
        // append affiliate data to url if one is available (e.g. as a widget); otherwise, the url is unmodified
        url = getAffiliateDecoratedURL(url);
    }

    if (options.queryParams) {
        const { url: parsedUrl, query } = queryString.parseUrl(url);

        // add properties within options.queryParams to URL’s search string
        Object.entries(options.queryParams).forEach(([ key, value ]) => {
            query[key] = value;
        });

        url = queryString.stringifyUrl({ url: parsedUrl, query });
    }

    if (options.isNewTab) {
        return void window.open(url, '_blank');
    }

    // Detecting iframes (https://www.geeksforgeeks.org/how-to-check-a-webpage-is-loaded-inside-an-iframe-or-into-the-browser-window-using-javascript/)
    if (window.self !== window.top) {
        const error = new Error('[navigateWindowTo.js] Trying to navigate from an iframe is not allowed');

        noticeError(error, {
            parentLocation: window.parent.location.href,
            frameLocation: window.location.href,

            targetUrl: url,
        });

        throw error;
    }

    if (options.isRedirect) {
        window.top.location.replace(url);
    } else {
        window.top.location.assign(url);
    }
}

export default navigateWindowTo;
