import { Config, fetchJSON } from '@ratehub/base-ui';

/**
 * Fetch the carousel for a province/insurance type
 * @param {string} insuranceType
 * @param {string} province
 */
export default async function fetchInsuranceCarousel(insuranceType, province) {
    const params = {
        url: Config.INSURANCE_PROVIDER_ASSIGNMENT_API_URL,
        params: { province, type: insuranceType },
    };

    return await fetchJSON(params, 'fetchInsuranceCarousel');
}
