import APPROVAL_LIKELIHOOD from '../definitions/ApprovalLikelihood';
import { PRODUCT_TYPES } from '../definitions/CurrentDocument';


const API_TO_APPROVAL_LIKELIHOOD = [
    APPROVAL_LIKELIHOOD.UNKNOWN,        // 0 -> 'unknown'
    APPROVAL_LIKELIHOOD.POOR,           // 1 -> 'poor'
    APPROVAL_LIKELIHOOD.FAIR,           // 2 -> 'fair'
    APPROVAL_LIKELIHOOD.GOOD,           // 3 -> 'good'
    APPROVAL_LIKELIHOOD.PREQUALIFIED,   // 4 -> 'prequalified'
];


/**
 * Maps an api approval likelihood value (as found in API response card_type.approval_likelihood)
 * into an entry within APPROVAL_LIKELIHOOD
 * @param {number} apiLikelihood
 * @param {string[]} cardTypes
 * @return {string} a value from APPROVAL_LIKELIHOOD, or undefined if unrecognized apiLikelihood
 */
function getApprovalLikelihoodFromAPIResponse(apiLikelihood, cardTypes) {
    // secured cards should always be considered good if unknown
    if (cardTypes.includes(PRODUCT_TYPES.SECURED) && apiLikelihood === 0) {
        return APPROVAL_LIKELIHOOD.GOOD;
    }

    return API_TO_APPROVAL_LIKELIHOOD[apiLikelihood];
}

/**
 * Maps an cardInfo APPROVAL_LIKELIHOOD value into a rank number where higher is more likely to be approved
 * @param {string} approvalLikelihood APPROVAL_LIKELIHOOD value
 * @return {number} index value of APPROVAL_LIKELIHOOD
 */
function getApprovalLikelihoodRank(approvalLikelihood) {
    return API_TO_APPROVAL_LIKELIHOOD.indexOf(approvalLikelihood);
}

export { getApprovalLikelihoodRank };
export default getApprovalLikelihoodFromAPIResponse;
