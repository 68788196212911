import React from 'react';
import PropTypes from 'prop-types';

import {
    ListItem as ListItemRoot,
} from '@ratehub/base-ui';

// NOTE: This component was moved to base-ui and the 
//  `body` prop was re-mapped to `description` to align
//  with other base-ui components
function ListItem({ body, ...otherProps }) {
    return (
        <ListItemRoot
            description={body}
            {...otherProps}
        />
    );
}

ListItem.propTypes = {
    body: PropTypes.string.isRequired,
};

ListItem.blockKey = 'rh/list-item';

export default ListItem;
