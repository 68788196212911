import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Typography from '../definitions/Typography';
import Sizes from '../definitions/Sizes';
import Colours from '../definitions/Colours';
import LINK_TARGETS from '../definitions/LinkTargets';
import AnchorWithIcon from './AnchorWithIcon';


const LIST_TYPES = {
    ORDERED: 'ordered',
    UNORDERED: 'unordered',
};

const ORDERED_LIST_ITEM_TYPE = {
    DECIMAL: 'decimal',
    UPPER_ALPHA: 'upper-alpha',
};

const ORDERED_LIST_VARIANT = {
    DEFAULT: 'default',
    CIRCLE: 'circle',
};

function ListItem({
    title, 
    description, 
    
    variant = ORDERED_LIST_VARIANT.DEFAULT, 
    listType = LIST_TYPES.ORDERED, 
    listStyleType = ORDERED_LIST_ITEM_TYPE.DECIMAL, 
    
    linkHref = undefined, 
    linkTarget = LINK_TARGETS.CURRENT_TAB, 
    
    className,

    ...otherProps 
}) {
    const hasLink = linkHref && linkTarget;

    return (
        <Container
            className={classNames(className, 'rh-position-relative', {
                'variant-default rh-mb-4': variant === ORDERED_LIST_VARIANT.DEFAULT,
                'variant-circle rh-mb-2': variant === ORDERED_LIST_VARIANT.CIRCLE,
                'type-ordered': listType === LIST_TYPES.ORDERED,
            })}
            variant={variant}
            listType={listType}
            listStyleType={listStyleType}
            {...otherProps}
        >
            <Choose>
                <When condition={hasLink}>
                    <AnchorWithIcon 
                        href={linkHref}
                        target={linkTarget === LINK_TARGETS.NEW_TAB ? '_blank' : '_self'}
                        message={title}
                        data-name="list-item-title-anchor"
                        shouldLowerCaseMessage={false}
                        className="list-item-title"
                    />
                </When>
                <Otherwise>
                    {/* Using a low level heading due to how this component is used */}
                    <h4
                        className={classNames('rh-mt-1 rh-mb-0_75 rh-fg-blueberry-dark weight-regular', {
                            'rh-title-m': variant === ORDERED_LIST_VARIANT.DEFAULT,
                            'rh-title-s leading-l': variant === ORDERED_LIST_VARIANT.CIRCLE,
                        })}
                    >
                        {title}
                    </h4>
                </Otherwise>
            </Choose>

            <p 
                className={classNames('rh-mt-0', {
                    'rh-text-l': variant === ORDERED_LIST_VARIANT.DEFAULT,
                    'rh-text-m leading-l': variant === ORDERED_LIST_VARIANT.CIRCLE,
                })}
            >
                {description}
            </p>
        </Container>
    );
}

ListItem.propTypes = {
    title: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
    
    variant: PropTypes.oneOf(Object.values(ORDERED_LIST_VARIANT)),
    listType: PropTypes.oneOf(Object.values(LIST_TYPES)),
    listStyleType: PropTypes.oneOf(Object.values(ORDERED_LIST_ITEM_TYPE)),
    
    linkHref: PropTypes.string,
    linkTarget: PropTypes.oneOf(Object.values(LINK_TARGETS)),
    
    className: PropTypes.string,
};


const LIST_INDENT = `calc(${ Sizes.SPACING.FOUR } + 4%)`;

const Container = styled.li`
    &.type-ordered {
        padding-left: ${LIST_INDENT};
    }

    /* Styles for ordered list bullet */
    &.type-ordered::before {
        content: counter(item, ${props => props.listStyleType});

        counter-increment: item 1;

        position: absolute;
        left: 0;
        top: 0;

        padding: 0;
    }

    &.variant-default::before {
        font-size: ${Sizes.ICONS['2XL']};
        font-weight: ${Typography.WEIGHTS.MEDIUM};
        line-height: 0.95; /* Adjustment to align top of the pseudo element with the title */

        color: ${Colours.BLUEBERRY_DARK};
    }

    &.variant-circle::before {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;

        flex-shrink: 0;

        width: ${Sizes.ICONS.L};
        height: ${Sizes.ICONS.L};

        font-size: ${Sizes.FONTS['M']};
        font-weight: ${Typography.WEIGHTS.MEDIUM};

        border-radius: 100%;

        box-sizing: border-box;

        color: ${Colours.BLUEBERRY_DARK};
        background-color: ${Colours.BLUEBERRY_LIGHTEST};
    }

    &:last-child {
        margin-bottom: 0;
    }
`;

export default ListItem;
export {
    LIST_TYPES,
    LIST_INDENT,
    ORDERED_LIST_ITEM_TYPE,
    ORDERED_LIST_VARIANT,
};
