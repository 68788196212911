function getGICsProductShape(product) {
    return {
        name: product.name,
        id: product.ids?.primary,
        // GIC currently lacks a slug field, so we use the primary ID as a fallback
        // this is because GIC doesn't have a CMS input field
        slug: product.slug ?? product.ids?.primary,
        provider: {
            name: product.provider?.name,
            logo: product.provider?.logo,
            slug: product.provider?.slug,
        },

        type: product.type,
        term: product.term,
        interest: product.interest?.rate,
        totalReturn: product.total_return,

        redeemable: product.redeemable,
        minimumInvestment: product.minimum_investment,

        detailsURL: product.urls?.details,
        applyURL: product.urls?.apply_redirect,

        isMonetized: product.monetized,
        // @TODO: Remove .sponsored after new deposits sponsored BE/CMS fully deployed
        isSponsored: product.sponsored?.table ?? product.sponsored,
        isCDIC: product.cdic,
        isPFAWinner: product.pfa_winner,

        eligibility: product.eligibility,

        interestPaidTerm: PAID_MAP[product.interest?.paid] ?? null,
        interestCompoundTerm: product.interest?.compounded ?? 'N/A',
        interestNotes: Array.isArray(product.interest?.notes)
            ? product.interest?.notes
            : [],
    };
}

const PAID_MAP = {
    annually: 'annually',
    'at-maturity': 'maturity',
};

export default getGICsProductShape;
