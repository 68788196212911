import React from 'react';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import Colours from '../definitions/Colours';
import MessagePropType from '../definitions/MessagePropType';
import messageToString from '../functions/messageToString';
import IconArrow from './icons/IconArrow';


function AnchorWithIcon({
    className,
    href,
    message,
    dataName,
    isDisabled = false,
    iconComponent,
    disableAnimation = false,
    shouldLowerCaseMessage = true,
    ...otherProps
}) {
    const intl = useIntl();
    const Icon = iconComponent ?? IconArrow;

    return (
        <a
            className={classNames(
                'rh-anchor-with-icon rh-text-xl rh-fg-blueberry-dark',
                { 
                    isDisabled: isDisabled,
                    'disable-animation' : disableAnimation,
                    'rh-text-lowercase' : shouldLowerCaseMessage,
                },
                className,
            )}
            data-name={dataName}
            href={isDisabled ? undefined : href}
            {...otherProps}
        >
            <span className="text-wrapper">
                {messageToString(message, intl)}
            </span>

            <Icon
                className="icon"
                outlineWidth="0"
                width="50"
                height="50"
                direction="right"
                stroke={Colours.BLUEBERRY_DARK}
            />
        </a>
    );
}

AnchorWithIcon.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    message: MessagePropType.isRequired,
    dataName: PropTypes.string,
    isDisabled: PropTypes.bool,
    iconComponent: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.object, // Promise - Lazy loaded icon
    ]),
    disableAnimation: PropTypes.bool,
    shouldLowerCaseMessage: PropTypes.bool,
};

export default observer(AnchorWithIcon);
