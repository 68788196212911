import React, { Suspense } from 'react';
import PropTypes from 'prop-types';


// Ideally LazyIcon would be using React.lazy so it aligns with our Suspense usage
function withSuspenseIconFallback(LazyIcon) {
    function WrapperComponent({ 
        className, 
        width, 
        height, 
        ...otherComponentProps 
    }) {
        // Fix for layout jank with lazy loaded icons
        // If we're provided one of width or height, assume our size is a square and set the other size
        const fallbackWidth = width ?? height ?? '100%';
        const fallbackHeight = height ?? width ?? '100%';
        
        // If the icon is supplied a size either via rh-icon-* class name or width/height props
        //  we want this to also apply to our fallback so the size remains the same before the
        //  icon loads.
        const style = className && className.includes('rh-icon-')
            ? {}
            : { width: fallbackWidth, height: fallbackHeight };

        return (
            <Suspense 
                fallback={(
                    <span
                        className={className}
                        style={style}
                    />
                )}
            >
                <LazyIcon 
                    className={className}
                    width={width}
                    height={height}
                    {...otherComponentProps} 
                />
            </Suspense>
        );
    }

    WrapperComponent.propTypes = {
        className: PropTypes.string,
        width: PropTypes.oneOfType([ 
            PropTypes.string,
            PropTypes.number,
        ]),
        height: PropTypes.oneOfType([ 
            PropTypes.string,
            PropTypes.number,
        ]),
    };

    return WrapperComponent;
}

export default withSuspenseIconFallback;